#rainbow, #rainbow>span {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    padding: 0px 1px;
    width: 100%;

    .color {
        flex-grow: 1;
        flex-shrink: 1;
        height: 3px;
    }
}
