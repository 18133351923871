footer {
	position: relative;
	padding: 2em 2em;
	background: #3a4146;
	text-align: left;
    letter-spacing: 0.04em;
    line-height: 1.5em;
    font-family: $ff-sans;
    font-weight: 300;
    font-size: 16px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    .card {
        -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
        -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
        -ms-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
        transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
        color: #bec9cf;
        text-decoration: none;
        border: 2.4px solid rgba(255, 255, 255, 0.2);
        border-radius: 48px;
        padding: 12px 24px;
        margin: 18px 24px;
        text-align: center;
        .val {
            vertical-align: 2px;
        }
    }
    .card:hover {
        color: #ffffff !important;
        background-color: rgb(67, 74, 80);
        border-color: rgba(255, 255, 255, 0.3);
    }
    .copy {
        color: #d0dbe0;
        text-decoration: none;
        margin: 8px;
        text-align: center;
        a {
            -moz-transition: color 0.2s ease-in-out, border-bottom-color 0.2s ease-in-out;
            -webkit-transition: color 0.2s ease-in-out, border-bottom-color 0.2s ease-in-out;
            -ms-transition: color 0.2s ease-in-out, border-bottom-color 0.2s ease-in-out;
            transition: color 0.2s ease-in-out, border-bottom-color 0.2s ease-in-out;
            border-bottom: dotted 1px rgba(255, 255, 255, 0.35);
            color: #bec9cf;
            text-decoration: none;
        }
        a:hover {
            border-bottom-color: transparent;
            color: #ffffff !important;
        }
    }
}
@media screen and (max-width: 1070px) {
    footer {
        .card {
            padding: 8px 16px;
            margin: 4px;
        }
    }
}