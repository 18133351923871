.whoami {
	position: relative;
	padding: 6em;
	background: #3a444a;
	text-align: center;
    color: #fff;
    letter-spacing: 0.05em;
    font-family: $ff-sans;
    .description {
        line-height: 1.8rem;
        font-size: 1.5rem;
        text-shadow: 2px 8px 2px #2f363c;
    }
    .description::selection{
        text-shadow: none;
        background-color: #fff;
        color: #0779cf;
    }
    .links {
        margin: 4em auto 0 auto;
        display: flex;
        justify-content: center;
        flex-wrap: wrap-reverse;
    }
    .profilebutton {
        font-size: 1.2rem;
        letter-spacing: 1px;
        font-weight: 600;
        border: none;
        font-size: 1.6em;
        box-shadow: 0 4px 0 0 #2f363c;
        padding: 0.75em 2em 0.75em 2em;
        margin: 1em 1.5em 1em 1.5em;
        background: #0a94ff;
        text-decoration: none;
        color: inherit;
        -moz-transition: background-color 0.2s ease-in-out;
        -webkit-transition: background-color 0.2s ease-in-out;
        -ms-transition: background-color 0.2s ease-in-out;
        transition: background-color 0.2s ease-in-out;
    }
    .profilebutton:hover {
        background: #006cbe;
    }
}
@media screen and (max-width: 1070px) {
    .whoami {
        padding: 2em;
        .description {
            font-size: 1rem;
        }
    }
}
