.brand {
    position: relative;
    text-align: center;
    padding: 0.5rem 0rem 2.5rem 0rem;
    background: $backgroundColor;
    color: #FFF;
    .name {
        margin: 2rem 0rem 0.5rem 0rem;
        text-transform: uppercase;
        font-size: 3em;
        letter-spacing: 16px;
        font-weight: 500;
        a {
            border-bottom: dotted 1px transparent;
            text-decoration: none;
            color: #ffffff;
            -moz-transition: color 0.2s ease-in-out, border-bottom 0.2s ease-in-out;
            -webkit-transition: color 0.2s ease-in-out, border-bottom 0.2s ease-in-out;
            -ms-transition: color 0.2s ease-in-out, border-bottom 0.2s ease-in-out;
            transition: color 0.2s ease-in-out, border-bottom 0.2s ease-in-out;
        }
        a:hover {
            border-bottom: dotted 1px rgba(255, 255, 255, 0.35);
        }
    }
    .pronouns {
        letter-spacing: 0.07em;
        font-size: 1.1em;
        color: rgba(255, 255, 255, 0.75);
    }
    .description {
        letter-spacing: 0.07em;
        font-size: 1.1em;
        color: rgba(255, 255, 255, 0.75);
        padding-bottom: 0.2rem;
    }
}
@media screen and (max-width: 1070px) {
    .brand {
        margin-top: 0px;
        padding: 1px 0px 3em 0px;
        .name {
            font-size: 2.5em;
        }
    }
}
